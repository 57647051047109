import { render, staticRenderFns } from "./ProductTile.vue?vue&type=template&id=2bfc7492&scoped=true&"
import script from "./ProductTile.vue?vue&type=script&lang=js&"
export * from "./ProductTile.vue?vue&type=script&lang=js&"
import style0 from "./ProductTile.vue?vue&type=style&index=0&id=2bfc7492&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bfc7492",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseChips: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseChips.vue').default,BaseImage: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseImage.vue').default,BaseHeading: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseHeading.vue').default,ProductPrice: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/product/ProductPrice.vue').default,PriceLowest: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/vendor/default/components/Price/PriceLowest.vue').default,ProductTileAddToCartButton: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/product/ProductTileAddToCartButton.vue').default,BaseButton: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseButton.vue').default})
